export async function cacheStorageDelete(
  window: Window,
  cacheName: string
): Promise<boolean> {
  try {
    return await window.caches.delete(cacheName);
  } catch (error) {
    console.warn(
      '%c%s[WARN]: %o',
      'color: orange; font-weight: bolder',
      cacheStorageDelete.name,
      error
    );
    return false;
  }
}

export function cacheStorageExists(window: Window): boolean {
  try {
    return !!window.caches;
  } catch (error) {
    console.warn(
      '%c%s[WARN]: %o',
      'color: orange; font-weight: bolder',
      cacheStorageExists.name,
      error
    );
    return false;
  }
}

export async function cacheStorageHas(
  window: Window,
  cacheName: string
): Promise<boolean> {
  try {
    return await window.caches.has(cacheName);
  } catch (error) {
    console.warn(
      '%c%s[WARN]: %o',
      'color: orange; font-weight: bolder',
      cacheStorageHas.name,
      error
    );
    return false;
  }
}

export async function cacheStorageKeys(window: Window): Promise<string[]> {
  try {
    return await window.caches.keys();
  } catch (error) {
    console.warn(
      '%c%s[WARN]: %o',
      'color: orange; font-weight: bolder',
      cacheStorageKeys.name,
      error
    );
    return [];
  }
}
