import { APP_INITIALIZER, Provider } from '@angular/core';

import { I18nService } from '../../services';

function initializeLanguage(i18nService: I18nService): () => void {
  return function (): void {
    return i18nService.init();
  };
}

export const LanguageInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeLanguage,
  deps: [I18nService],
  multi: true,
};
