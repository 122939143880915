import { APP_INITIALIZER, Provider } from '@angular/core';
import {
  HttpBackend,
  HttpClient,
  HttpHeaders,
  HttpParams,
} from '@angular/common/http';
import { SwUpdate } from '@angular/service-worker';
import {
  CACHE_BUSTING_MAX_DURATION,
  SHOPPER_PAYMENT_PORTAL_VERSION as APP_VERSION,
} from '@environment/variables';
import {
  cacheStorageDelete,
  cacheStorageExists,
  cacheStorageHas,
  cacheStorageKeys,
} from '@shared/common/functions/window/cache-storage';
import { serviceWorkerUnregisterAll } from '@shared/common/functions/window/service-worker';
import {
  catchError,
  delay,
  from,
  map,
  Observable,
  of,
  race,
  switchMap,
  tap,
} from 'rxjs';

import { WINDOW } from '../../constants';

//#region TYPES

type Meta = { version: string; history: string[] };

//#endregion TYPES

/**
 * Load newest application version.
 * @param {SwUpdate} swUpdate service to hard reload page.
 * @param {HttpBackend} httpBackend to generate an `HttpClient` instance.
 * @param {Window} window reference.
 */
function initializeCacheBuster(
  swUpdate: SwUpdate,
  httpBackend: HttpBackend,
  window: Window
): () => Observable<boolean> {
  const metaFilename = '/assets/global/meta.json';

  return function (): Observable<boolean> {
    return race(
      serviceWorkerUnregisterAll(window),
      cacheBustingTimer(false)
    ).pipe(
      switchMap((): Observable<boolean> => {
        return race(deleteMetaFromCache(), cacheBustingTimer(false));
      }),
      switchMap((): Observable<boolean> => verifyMetaVersion()),
      switchMap((reload: boolean): Observable<boolean> => {
        if (!reload) return of(true);
        return race(reloadCache(), cacheBustingTimer([])).pipe(
          switchMap((): Observable<boolean> => updatePWA())
        );
      }),
      catchError((error: Error): Observable<boolean> => {
        console.error(
          '%c%s[ERROR]: %o',
          'color: red; font-weight: bolder',
          initializeCacheBuster.name,
          error
        );
        return of(false);
      })
    );
  };

  function deleteMetaFromCache(): Observable<boolean> {
    if (!cacheStorageExists(window)) return of(false);
    return from(cacheStorageHas(window, metaFilename)).pipe(
      switchMap((isCached: boolean): Observable<boolean> => {
        if (isCached) return from(cacheStorageDelete(window, metaFilename));
        return of(false);
      })
    );
  }

  function verifyMetaVersion(): Observable<boolean> {
    const http: HttpClient = new HttpClient(httpBackend);
    const headers: HttpHeaders = new HttpHeaders({
      'Cache-Control':
        'no-cache, no-store, must-revalidate, post-check=0, pre-check=0',
      Pragma: 'no-cache',
      Expires: '0',
    });
    const params: HttpParams = new HttpParams({
      fromObject: { timestamp: Date.now() },
    });
    return http
      .get<Meta>(metaFilename, { headers, params })
      .pipe(map(({ version }: Meta): boolean => version !== APP_VERSION));
  }

  function updatePWA(): Observable<boolean> {
    return from(swUpdate.checkForUpdate()).pipe(
      switchMap((areThereUpdates: boolean): Observable<boolean> => {
        if (areThereUpdates) return from(swUpdate.activateUpdate());
        return of(areThereUpdates);
      }),
      tap((areThereUpdates: boolean): void => {
        areThereUpdates && window.location.reload();
      })
    );
  }

  function reloadCache(): Observable<boolean[]> {
    if (!cacheStorageExists(window)) return of([]);
    return from(cacheStorageKeys(window)).pipe(
      switchMap((keys: string[]): Observable<boolean[]> => {
        return from(
          Promise.all(
            keys.map((key: string): Promise<boolean> => {
              return cacheStorageDelete(window, key);
            })
          )
        );
      })
    );
  }

  function cacheBustingTimer<Output = unknown>(
    output: Output
  ): Observable<Output> {
    return of(true).pipe(
      delay(CACHE_BUSTING_MAX_DURATION),
      map((): Output => output)
    );
  }
}

export const CacheBusterInitializerProvider: Provider = {
  provide: APP_INITIALIZER,
  useFactory: initializeCacheBuster,
  deps: [SwUpdate, HttpBackend, WINDOW],
  multi: true,
};
