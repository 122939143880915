import { Inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { NAVIGATOR, WINDOW } from '../../../constants';
import { LocalStorageService } from '../../../services/storage';
import { LANGUAGE, SUPPORTED_LANGUAGES } from '../constants';
import { Language } from '../models';

@Injectable({ providedIn: 'root' })
export class I18nService {
  public get current(): Language {
    const [, queryParams]: string[] = this._window.location.href.split('?');
    const params: URLSearchParams = new URLSearchParams(queryParams);
    let lang: string | null = params.get('lang');
    if (lang) {
      lang = lang.replace('-', '_');
      const langCode: Language | null = this._findLanguage(lang);
      if (langCode) return langCode;
    }

    lang = this._storageService.get<string>(LANGUAGE);
    if (lang) {
      const langCode: Language | null = this._findLanguage(lang);
      if (langCode) return langCode;
    }

    lang = this._navigator.language.replace('-', '_');
    const langCode: Language | null = this._findLanguage(lang);
    if (langCode) return langCode;

    return SUPPORTED_LANGUAGES.find((language: Language): boolean => {
      return language.default;
    }) as Language;
  }

  public set current(lang: Language) {
    this._storageService.set<string>(LANGUAGE, lang.code);
    this._translateService.use(lang.code);
  }

  constructor(
    @Inject(WINDOW) private readonly _window: Window,
    @Inject(NAVIGATOR) private readonly _navigator: Navigator,
    private readonly _translateService: TranslateService,
    private readonly _storageService: LocalStorageService
  ) {}

  //#region PUBLIC

  public init(): void {
    if (this._translateService.currentLang) return;

    this._translateService.addLangs(
      SUPPORTED_LANGUAGES.map((language: Language): string => language.code)
    );
    this._storageService.set<string>(LANGUAGE, this.current.code);
    this._translateService.setDefaultLang(this.current.code);
    this._translateService.use(this.current.code);
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _findLanguage(lang: string): Language | null {
    const langCode: Language | undefined = SUPPORTED_LANGUAGES.find(
      ({ code }: Language): boolean => code === lang
    ) as Language;
    if (langCode) return langCode;
    return null;
  }

  //#endregion PRIVATE
}
