import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ServiceWorkerModule } from '@angular/service-worker';

import { isRunningInLocalhost } from '../../functions/window';

@NgModule({
  imports: [
    CommonModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      // Register the ServiceWorker as soon as the application is stable or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
      enabled: !isRunningInLocalhost(document),
    }),
  ],
  exports: [ServiceWorkerModule],
})
export class ServiceWorkerConfigModule {}
