import { HttpHeaders } from '@angular/common/http';

export function verifyHttpCodeInHeaders(
  header: string,
  headers: HttpHeaders,
  status: number
): boolean {
  if (!headers.has(header)) return false;

  const codeStr: string | null = headers.get(header);
  if (!codeStr) return false;

  const codes: string[] = codeStr.split(',');
  for (const code of codes) {
    if (+code === status) return true;
  }
  return false;
}
