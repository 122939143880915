import { InjectionToken } from '@angular/core';
import { DatadogConfig } from '@shared/entities/common/models/datadog';

import { VERSION } from '../enums';

export const API_VERSION: InjectionToken<VERSION> = new InjectionToken<VERSION>(
  'API_VERSION'
);

export const BINANCE_PROD_PAYMENT: InjectionToken<boolean> =
  new InjectionToken<boolean>('BINANCE_PROD_PAYMENT');

export const DATADOG_CONFIG: InjectionToken<DatadogConfig> =
  new InjectionToken<DatadogConfig>('DATADOG_CONFIG');

export const LOCAL_STORAGE_CLEANER_INITIALIZER: InjectionToken<Storage> =
  new InjectionToken<Storage>('LOCAL_STORAGE_CLEANER_INITIALIZER');

export const NAVIGATOR: InjectionToken<Navigator> =
  new InjectionToken<Navigator>('NAVIGATOR');

export const WALLET_CONNECT_PROJECT_ID: InjectionToken<string> =
  new InjectionToken<string>('WALLET_CONNECT_PROJECT_ID');

export const WINDOW: InjectionToken<Window> = new InjectionToken<Window>(
  'WINDOW'
);
