export async function serviceWorkerUnregisterAll(
  window: Window
): Promise<void> {
  try {
    if (!window?.navigator?.serviceWorker) return;

    const registrations: readonly ServiceWorkerRegistration[] =
      await window.navigator.serviceWorker.getRegistrations();

    await Promise.all(
      registrations.map(
        (registration: ServiceWorkerRegistration): Promise<boolean> => {
          return registration.unregister();
        }
      )
    );
  } catch (error) {
    console.warn(
      '%c%s[WARN]: %o',
      'color: orange; font-weight: bolder',
      serviceWorkerUnregisterAll.name,
      error
    );
  }
}
