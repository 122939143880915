import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { Provider } from '@angular/core';

import { LanguageInterceptor } from '../../interceptors';

export const LanguageInterceptorProvider: Provider = {
  provide: HTTP_INTERCEPTORS,
  useClass: LanguageInterceptor,
  multi: true,
};
