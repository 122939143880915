import { ENVIRONMENT } from '@shared/entities/core/types';

export const SHOPPER_PAYMENT_PORTAL_VERSION = '1.12.0';
export const SUFFIX_ENV_SCRIPT = '';
export const CURRENT_ENVIRONMENT: ENVIRONMENT = 'UAT';
export const CACHE_BUSTING_MAX_DURATION = 3_000;
export const RESOURCES_URL = 'https://uat-resources.zenki.fi/';
export const ZENKIPAY_ASSETS_URL =
  'https://uat-resources.zenki.fi/zenkipay/script/assets/';
export const SCRIPT_ASSETS_URL =
  'https://uat-resources.zenki.fi/zenkipay/script/v2/assets/';
export const ZENKIPAY_SCRIPT_URL =
  'https://uat-resources.zenki.fi/zenkipay/script/zenkipay.js';
export const ZENKIPAY_URL = 'https://payments-uat.zenki.fi/#/';
export const SHOPPER_PORTAL_URL = 'https://portal-uat.shopper.zenki.fi/#/';
export const API_URL = 'https://uat-gateway.zenki.fi/';
export const WALLET_BROWSER_INTEGRATED_PAYER_URL =
  'https://uat-wallet-mobile.zenki.fi/#/';
