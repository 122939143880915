import { json2String, string2Json } from '@shared/common/functions/objects';

export abstract class StorageService {
  protected abstract readonly _storage: Storage;

  public clear(): void {
    this._storage.clear();
  }

  public get<T = unknown>(key: string): T | null {
    const value: string | null = this._storage.getItem(key);
    if (value) return string2Json<T>(value);
    return null;
  }

  public set<T = unknown>(key: string, input: T): void {
    const value: string = json2String(input);
    this._storage.setItem(key, value);
  }

  public delete(key: string): void {
    this._storage.removeItem(key);
  }
}
