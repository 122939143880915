import { Injectable } from '@angular/core';
import { SessionStorageService } from '@shared/angular/services/storage';
import { Web3TransactionParams } from '@shared/entities/modules/wallet/modules/integrations/modules/web3/models/web3';
import { BehaviorSubject, Observable } from 'rxjs';

import { TRX_HASH, WEB3_TRANSACTION_PARAMS } from '../constants';

@Injectable({ providedIn: 'root' })
export class PayerWeb3Service {
  private readonly _trxParams$: BehaviorSubject<Web3TransactionParams | null> =
    new BehaviorSubject<Web3TransactionParams | null>(
      this._storage.get<Web3TransactionParams>(WEB3_TRANSACTION_PARAMS)
    );
  public readonly trxParams$: Observable<Web3TransactionParams | null> =
    this._trxParams$.asObservable();

  public get trxParams(): Web3TransactionParams | null {
    return this._trxParams$.value;
  }
  public set trxParams(trxParams: Web3TransactionParams | null) {
    if (trxParams) {
      this._storage.set<Web3TransactionParams>(
        WEB3_TRANSACTION_PARAMS,
        trxParams
      );
    } else this._storage.delete(WEB3_TRANSACTION_PARAMS);
    this._trxParams$.next(trxParams);
  }

  private readonly _trxHash$: BehaviorSubject<string | null> =
    new BehaviorSubject<string | null>(this._storage.get<string>(TRX_HASH));
  public readonly trxHash$: Observable<string | null> =
    this._trxHash$.asObservable();

  public get trxHash(): string | null {
    return this._trxHash$.value;
  }
  public set trxHash(trxHash: string | null) {
    if (trxHash) this._storage.set<string>(TRX_HASH, trxHash);
    else this._storage.delete(TRX_HASH);
    this._trxHash$.next(trxHash);
  }

  constructor(private readonly _storage: SessionStorageService) {}
}
