import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';
import { catchError, delay, Observable, of, switchMap, throwError } from 'rxjs';

import { ApplyRequestRetryerInterceptor } from '../../constants';
import { verifyHttpCodeInHeaders } from '../../functions/verify-http-code-in-headers';

@Injectable()
export class RequestRetryerInterceptor implements HttpInterceptor {
  private readonly _TOTAL_INTENTS = 2;
  private readonly _DELAY = 1_000;

  //#region PUBLIC

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    return this._sendRequest(request, next);
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _sendRequest(
    request: HttpRequest<unknown>,
    next: HttpHandler,
    currentIntent = 0
  ): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse): Observable<HttpEvent<unknown>> => {
        if (currentIntent >= this._TOTAL_INTENTS) {
          return throwError((): HttpErrorResponse => error) as Observable<
            HttpEvent<unknown>
          >;
        }

        if (error.status < 500) {
          const notApplyInterceptor = !verifyHttpCodeInHeaders(
            ApplyRequestRetryerInterceptor,
            request.headers,
            error.status
          );

          if (notApplyInterceptor) {
            return throwError((): HttpErrorResponse => error) as Observable<
              HttpEvent<unknown>
            >;
          }
        }

        return of(true).pipe(
          delay(this._DELAY),
          switchMap((): Observable<HttpEvent<unknown>> => {
            return this._sendRequest(request, next, ++currentIntent);
          })
        );
      })
    );
  }

  //#endregion PRIVATE
}
