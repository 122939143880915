import { isPlatformBrowser } from '@angular/common';
import { PLATFORM_ID, Provider } from '@angular/core';

import { WINDOW } from '../../constants';

export const WindowProvider: Provider = {
  provide: WINDOW,
  deps: [PLATFORM_ID],
  useFactory(platformId: string): Window | null {
    return isPlatformBrowser(platformId) ? window : null;
  },
};
