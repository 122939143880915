import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Observable, map, take } from 'rxjs';

import { PayerWeb3Service } from '../../web3/services';

export const processingPaymentGuard: CanActivateFn =
  (): Observable<boolean> => {
    const router: Router = inject(Router);
    const payerWeb3Service: PayerWeb3Service = inject(PayerWeb3Service);

    return payerWeb3Service.trxHash$.pipe(
      take(1),
      map((trxHash: string | null): boolean => {
        if (trxHash) return true;
        router.navigate(['not-found']);
        return false;
      })
    );
  };
