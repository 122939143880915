import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Routes } from '@angular/router';
import { processingPaymentGuard } from '@core/payer/modules/common/guards';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'not-found',
    pathMatch: 'full',
  },
  {
    path: 'web3',
    loadChildren: () =>
      import('./pages/web3-page').then((m) => m.Web3PageModule),
  },
  {
    path: 'cancel',
    loadComponent: () =>
      import('./pages/confirm-cancel-page').then(
        (c) => c.ConfirmCancelPageComponent
      ),
  },
  {
    path: 'processing',
    canActivate: [processingPaymentGuard],
    loadComponent: () =>
      import('./pages/processing-payment-page').then(
        (c) => c.ProcessingPaymentPageComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/not-found-page').then((c) => c.NotFoundPageComponent),
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabledBlocking',
      useHash: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
