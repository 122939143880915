import { Inject, Injectable, Optional } from '@angular/core';
import { useStorage } from '@shared/common/functions/storage';

import { WINDOW } from '../../constants';
import { StorageService } from './storage.service';

@Injectable({ providedIn: 'root' })
export class SessionStorageService extends StorageService {
  protected override readonly _storage: Storage;

  constructor(@Optional() @Inject(WINDOW) window: Window) {
    super();
    try {
      this._storage = window.sessionStorage;
    } catch (error) {
      this._storage = useStorage();
    }
  }
}
