import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import { SkipLanguageInterceptor } from '../../../constants';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  constructor(private readonly _translate: TranslateService) {}

  //#region PUBLIC

  public intercept(
    request: HttpRequest<unknown>,
    next: HttpHandler
  ): Observable<HttpEvent<unknown>> {
    if (request.headers.has(SkipLanguageInterceptor)) {
      return next.handle(request);
    }
    return next.handle(this._addHeaders(request));
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _addHeaders(request: HttpRequest<unknown>): HttpRequest<unknown> {
    let language = (this._translate.currentLang || 'en_US').replace('_', '-');
    if (language === 'en-US') language += ';q=0.8,*;q=0.7';
    else language += ',en-US;q=0.8,*;q=0.7';
    return request.clone({
      setHeaders: {
        'Accept-Language': language,
        'Content-Language': language,
      },
    });
  }

  //#endregion PRIVATE
}
