export function useStorage(): Storage {
  const map: Map<string, string> = new Map<string, string>();

  return {
    get length(): number {
      return map.size;
    },
    clear(): void {
      map.clear();
    },
    getItem(key: string): string | null {
      return map.get(key) || null;
    },
    key(index: number): string | null {
      const keys: string[] = Array.from(map.keys());
      return keys[index];
    },
    removeItem(key: string): void {
      map.delete(key);
    },
    setItem(key: string, value: string): void {
      map.set(key, value);
    },
  };
}
