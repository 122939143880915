import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InternationalizationModule } from '@shared/angular/modules/i18n';
import { ServiceWorkerConfigModule } from '@shared/angular/modules/service-worker';
import { LanguageInterceptorProvider } from '@shared/angular/modules/i18n/providers/language.interceptor';
import { LanguageInitializerProvider } from '@shared/angular/modules/i18n/providers/language.initializer';
import { CacheInterceptorProvider } from '@shared/angular/providers/cache.interceptor';
import { LoadingInterceptorProvider } from '@shared/angular/modules/loading/providers';
import { RequestRetryerInterceptorProvider } from '@shared/angular/providers/request-retryer.interceptor';
import { NavigatorProvider } from '@shared/angular/providers/navigator';
import { WindowProvider } from '@shared/angular/providers/window';
import { CacheBusterInitializerProvider } from '@shared/angular/providers/cache-buster.initializer';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';

@NgModule({
  bootstrap: [AppComponent],
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    InternationalizationModule,
    ServiceWorkerConfigModule,
    AppRoutingModule,
  ],
  providers: [
    CacheBusterInitializerProvider,
    LanguageInitializerProvider,
    CacheInterceptorProvider,
    LoadingInterceptorProvider,
    RequestRetryerInterceptorProvider,
    LanguageInterceptorProvider,
    NavigatorProvider,
    WindowProvider,
  ],
})
export class AppModule {}
