<section class="wrapper">
  <svg
    class="zenki-logo"
    width="301"
    height="323"
    viewBox="0 0 301 323"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M272.198 11.8567C283.645 13.6525 293.238 21.468 297.304 32.3105C301.369 43.1531 299.276 55.3429 291.828 64.2123C237.628 128.764 140.485 244.434 140.485 244.434C185.872 246.966 233.869 241.56 281.83 233.524C288.126 232.479 294.439 235.289 297.872 240.665C301.305 246.042 301.195 252.946 297.593 258.211C283.211 279.337 266.202 298.985 246.48 314.068C242.318 317.257 237.332 319.192 232.108 319.647C162.752 325.549 95.7764 321.178 28.1787 310.434C16.7312 308.638 7.13818 300.823 3.07289 289.98C-0.992404 279.138 1.09983 266.948 8.54861 258.079C62.7483 193.527 159.891 77.8563 159.891 77.8563C114.505 75.3252 66.5067 80.7307 18.5463 88.7668C12.2502 89.8121 5.93746 87.0019 2.50459 81.6256C-0.928291 76.2494 -0.818556 69.3448 2.78329 64.0801C17.1658 42.9534 34.175 23.3055 53.8968 8.22235C58.0582 5.03376 63.0441 3.09847 68.2684 2.64412C137.625 -3.25802 204.6 1.11312 272.198 11.8567H272.198Z"
      fill="url(#paint0_linear_15435_88592)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_15435_88592"
        x1="60.7259"
        y1="3.92664"
        x2="239.567"
        y2="317.452"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0%" stop-color="lightblue" stop-opacity=".75">
          <animate
            attributeName="stop-color"
            values="#FA01AD;blue;red;red;#771ddd;red;red;#FE9301;#FA01AD"
            dur="3s"
            repeatCount="indefinite"
          />
        </stop>
        <stop offset="100%" stop-color="lightblue" stop-opacity=".75">
          <animate
            attributeName="stop-color"
            values="#FE9301;orange;purple;purple;#771ddd;purple;purple;#FA01AD;#FE9301"
            dur="3s"
            repeatCount="indefinite"
          />
          <animate
            attributeName="offset"
            values=".95;.80;.60;.40;.20;0;.20;.40;.60;.80;.95"
            dur="3s"
            repeatCount="indefinite"
          />
        </stop>
      </linearGradient>
    </defs>
  </svg>
</section>
