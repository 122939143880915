import { Injectable } from '@angular/core';
import { ComponentPortal } from '@angular/cdk/portal';
import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { BehaviorSubject, debounceTime, filter, Subscription } from 'rxjs';

import { SessionStorageService } from '../../../services/storage';
import { LoadingComponent } from '../components';
import { LOADING } from '../constants';

@Injectable({ providedIn: 'root' })
export class LoadingService {
  private readonly _DELAY = 250;
  private readonly _overlayRef!: OverlayRef;

  private readonly _urls$: BehaviorSubject<Set<string>> = new BehaviorSubject(
    new Set<string>()
  );

  constructor(
    overlay: Overlay,
    private readonly _storageService: SessionStorageService
  ) {
    this._overlayRef = overlay.create();
    _storageService.delete(LOADING);
    this._handleShowSpinnerChanges();
    this._handleHideSpinnerChanges();
  }

  //#region PUBLIC

  public show(url: string): void {
    if (!url) {
      return console.warn(
        '%c%s.%s: Url was not provided.',
        'color: orange; font-weight: bolder',
        this.constructor.name,
        this.show.name
      );
    }

    const urls: Set<string> = this._urls$.value;

    const alreadyExists: boolean = urls.has(url);
    if (alreadyExists) return;

    urls.add(url);
    this._storageService.set(LOADING, urls);
    this._urls$.next(urls);
  }

  public hide(url: string): void {
    if (!url) {
      return console.warn(
        '%c%s.%s: Url was not provided.',
        'color: orange; font-weight: bolder',
        this.constructor.name,
        this.show.name
      );
    }

    const urls: Set<string> = this._urls$.value;

    const cannotBeDeleted = !urls.delete(url);
    if (cannotBeDeleted) return;

    this._storageService.set(LOADING, urls);
    this._urls$.next(urls);
  }

  //#endregion PUBLIC

  //#region PRIVATE

  private _handleShowSpinnerChanges(): Subscription {
    return this._urls$
      .pipe(
        filter((urls: Set<string>): boolean => !!urls.size),
        filter((): boolean => !this._overlayRef.hasAttached())
      )
      .subscribe((): void => {
        const loadingOverlayPortal: ComponentPortal<LoadingComponent> =
          new ComponentPortal<LoadingComponent>(LoadingComponent);
        this._overlayRef.attach(loadingOverlayPortal);
      });
  }

  private _handleHideSpinnerChanges(): Subscription {
    return this._urls$
      .pipe(
        debounceTime(this._DELAY),
        filter((urls: Set<string>): boolean => !urls.size)
      )
      .subscribe((): void => {
        this._storageService.delete(LOADING);
        this._overlayRef.detach();
      });
  }

  //#endregion PRIVATE
}
