import { isEqual } from '@shared/common/functions/objects';
import {
  fromEvent,
  debounceTime,
  map,
  distinctUntilChanged,
  Observable,
  Subscription,
} from 'rxjs';

import { WindowSize } from '../../models';

/**
 * Get window resize changes by an observable.
 * @param {Window} window you must set it by DI.
 * @param {number} delayTime (optional) default value is `500`.
 * @returns {Observable<WindowSize>} Window resize changes.
 */
export function getWindowResizeObservable(
  window: Window,
  delayTime = 500
): Observable<WindowSize> {
  return fromEvent<Event>(window, 'resize').pipe(
    debounceTime(delayTime),
    map(({ target }: Event): Window => target as Window),
    map(
      ({ innerHeight, innerWidth }: Window): WindowSize => ({
        height: innerHeight,
        width: innerWidth,
      })
    ),
    distinctUntilChanged(isEqual)
  );
}

/**
 * Prevent redirects in the page.
 * @param {Window} window reference.
 * @returns {Subscription} subscription to can be unsubscribed.
 */
export function preventRedirects(window: Window): Subscription {
  return fromEvent(window, 'beforeunload').subscribe(
    (event: BeforeUnloadEvent): void => {
      event.returnValue = true;
      event.preventDefault();
    }
  );
}

/**
 * Return a boolean indicating if the current environment is running in localhost.
 * @param {Document} document - The document object.
 * @returns {boolean} - A boolean indicating if the current environment is running in localhost.
 */
export function isRunningInLocalhost(document: Document): boolean {
  const hostnames: string[] = ['127.0.0.1', 'localhost'];
  const hostname: string = document.location.hostname;
  return hostnames.includes(hostname);
}
