export const PaymentSignatureHeader = 'X-Payment-Signature';

export const SkipAuthInterceptor = 'Skip-Authorization-Interceptor';

export const SkipHttpErrorInterceptor = 'Skip-Http-Error-Interceptor';

export const SkipAllHttpErrorInterceptor = 'Skip-All-Http-Error-Interceptor';

export const SkipLanguageInterceptor = 'Skip-Language-Interceptor';

export const ApplyRequestRetryerInterceptor =
  'Apply-Request-Retryer-Interceptor';
